import styled from 'styled-components';

export const Thumbnail = styled.figure`
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '0')};

  ${({ isRound }) =>
    isRound &&
    `
    border-radius: 50%;
    overflow: hidden;    
  `}
`;

export const ThumbnailHoverContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;

  .title,
  .subtitle {
    position: relative;
    z-index: 5;
  }

  figure {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    transform: translateY(0);
    margin: 0;

    transition: all 225ms ease-in-out;
  }

  &:hover {
    figure {
      opacity: 0.25;
      transform: translateY(200%);
    }
  }
`;
