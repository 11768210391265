import { Container } from 'components/common';
import Logo from 'components/Logo';
import { Link } from 'gatsby';
import { useTheme } from 'hooks/useTheme';
import React from 'react';
import NavbarLinks from '../NavbarLinks';
import { Brand, Wrapper } from './styles';

const Navbar = () => {
  const { theme } = useTheme();

  return (
    <Wrapper as={Container}>
      <Brand as={Link} to="/" theme={theme}>
        <span>Griffen Fargo</span>
        <Logo />
      </Brand>

      <NavbarLinks desktop />
    </Wrapper>
  );
};

export default Navbar;
