import config from 'data/config';
import styled from 'styled-components';
import useThemeVar from 'hooks/useThemeVar';

export const Label = styled.span`
  display: ${({ inline }) => (inline ? 'inline' : 'inline-block')};
  padding: 0.3rem 0.5rem;

  color: ${() => useThemeVar(config.theme.primary, config.theme.secondary)};

  background: ${() => useThemeVar(config.theme.lightBg, config.theme.darkGray)};

  border-radius: 0.15rem;
  line-height: 2rem;
`;
