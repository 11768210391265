import config from 'data/config';
import useThemeVar from 'hooks/useThemeVar';
import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(
    ${({ columns }) => (columns ? columns : '3')},
    1fr
  );
  grid-template-rows: 8fr;
  gap: 1.2rem 1.2rem;

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;

export const Item = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);

  h3,
  h4 {
    color: ${() => useThemeVar(config.theme.darkBg, config.theme.white)};
  }

  p {
    color: ${() => useThemeVar(config.theme.gray, config.theme.lightGray)};
  }
`;
