import config from 'data/config';
import useThemeVar from 'hooks/useThemeVar';
import styled from 'styled-components';

export const Card = styled.div`
  padding: 1rem;
  background: ${() => useThemeVar(config.theme.white, config.theme.black)};
  height: 100%;
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
