import { useMatch } from '@reach/router';
import ToggleTheme from 'components/theme/Header/ToggleTheme';
import config from 'data/config';
import { Link } from 'gatsby';
import { useTheme } from 'hooks/useTheme';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { VirtualRealitySVG } from './components/virtual-reality.svg';
import { IconWrapper, Wrapper } from './styles';

const NavbarLinks = ({ desktop }) => {
  const isHome = useMatch('/');
  const { theme } = useTheme();

  return (
    <Wrapper desktop={desktop}>
      {isHome ? (
        <>
          <AnchorLink href="#activity" className="nav-link">
            About
          </AnchorLink>
          <Link to="/#experience" className="nav-link">
            Experience
          </Link>
          <AnchorLink href="#contact" className="nav-link">
            Contact
          </AnchorLink>
        </>
      ) : (
        <>
          <Link to="/" className="nav-link">
            Home
          </Link>
          <Link to="/#activity" className="nav-link">
            About
          </Link>
          <Link to="/#experience" className="nav-link">
            Experience
          </Link>
          <Link to="/#contact" className="nav-link">
            Contact
          </Link>
        </>
      )}

      <Link to={`/${config.routes.projects}`} className="nav-link">
        Projects
      </Link>

      <Link to={`/${config.routes.posts}`} className="nav-link">
        Blog
      </Link>

      <a
        href="https://zen.griffen.codes"
        target="_blank"
        rel="noreferrer"
        data-tip={`
              <div class="tooltip-container">
                <span>
                  Experimental VR Playground
                </span>
              </div>
            `}
      >
        <IconWrapper>
          <VirtualRealitySVG
            fill={theme === 'light' ? null : config.theme.secondary}
          />
        </IconWrapper>
      </a>
      <ToggleTheme />
    </Wrapper>
  );
};

export default NavbarLinks;
