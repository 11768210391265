import { Thumbnail } from 'components/common';
import config from 'data/config';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import useThemeVar from 'hooks/useThemeVar';
import React from 'react';

const Logo = ({ alt = config.defaultTitle }) => {
  const { lightLogo, darkLogo } = useStaticQuery(graphql`
    {
      lightLogo: file(relativePath: { eq: "logo/logo-light.png" }) {
        childImageSharp {
          gatsbyImageData(width: 128, layout: FIXED, placeholder: BLURRED)
        }
      }
      darkLogo: file(relativePath: { eq: "logo/logo-dark.png" }) {
        childImageSharp {
          gatsbyImageData(width: 128, layout: FIXED, placeholder: BLURRED)
        }
      }
    }
  `);

  const image = useThemeVar(darkLogo, lightLogo);

  return (
    <Thumbnail>
      <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={alt} />
    </Thumbnail>
  );
};

export default Logo;
