import config from 'data/config';
import useThemeVar from 'hooks/useThemeVar';
import styled from 'styled-components';

export const Tag = styled.span`
  margin-bottom: 0.5rem;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  align-items: center;

  border-radius: 0.4em;

  display: inline-flex;
  font-size: 0.85em;
  height: 2em;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  border-color: ${() =>
    useThemeVar(config.theme.primary, config.theme.secondary)};
  transition: background-color 225ms ease-in-out;

  a {
    color: ${() => useThemeVar(config.theme.primaryBg, config.theme.secondary)};
  }

  &:hover {
    background-color: ${() =>
      useThemeVar(config.theme.primary, config.theme.secondary)};

    a {
      color: ${() => useThemeVar(config.theme.lightBg, config.theme.darkBg)};
    }
  }
`;
