import React from 'react';
import { Container } from 'components/common';
import { Wrapper, BackgroundImage, Flex, Links, Details } from './styles';
import Socials from './components/Socials';
import { useTheme } from 'hooks/useTheme';
import { timeDifference } from 'utils/timeDifference';
import NavbarLinks from '../Header/NavbarLinks';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

export const Footer = ({ context }) => {
  const { theme } = useTheme();
  const { buildTimestamp } = context;

  const { footerBackgroundImage } = useStaticQuery(graphql`
    {
      footerBackgroundImage: file(
        relativePath: { eq: "illustrations/ground-zero.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Flex as={Container}>
        <Details theme={theme}>
          <h2>Griffen Fargo</h2>
          <h4>
            Made with{' '}
            <span aria-label="love" role="img">
              💖
            </span>{' '}
            and{' '}
            <span aria-label="coffee" role="img">
              ☕
            </span>
          </h4>
          <p>
            {new Date().getFullYear()} © All rights are reserved | updated{' '}
            {timeDifference(new Date().getTime(), buildTimestamp)}
          </p>
        </Details>
        <div>
          <Links theme={theme}>
            <NavbarLinks desktop />
          </Links>
          <Links theme={theme}>
            <Socials />
          </Links>
        </div>
      </Flex>
      <BackgroundImage>
        <GatsbyImage
          image={footerBackgroundImage.childImageSharp.gatsbyImageData}
        />
      </BackgroundImage>
    </Wrapper>
  );
};
