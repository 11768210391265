import footerBackgroundMask from 'assets/illustrations/footer.svg';
import styled from 'styled-components';
import config from 'data/config';
import useThemeVar from 'hooks/useThemeVar';

export const Wrapper = styled.div`
  position: relative;
  padding: 8rem 0 4rem 0;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;

  overflow: hidden;

  @media (max-width: 1960px) {
    padding: 10rem 0 4rem;
  }
`;

export const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.12;
  bottom: -15%;
  left: 0;
  z-index: 0;

  mask-image: url(${footerBackgroundMask});
  mask-repeat: no-repeat;
  mask-size: cover;
`;

export const Flex = styled.div`
  display: flex;
  z-index: 1;
  position: relative;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 680px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;

export const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0.6rem;

  a {
    margin: 0 0.5rem;

    img {
      filter: ${() =>
        useThemeVar(
          '',
          'invert(89%) sepia(72%) saturate(566%) hue-rotate(315deg) brightness(100%) contrast(101%)'
        )};

      margin: 0;

      &:hover {
        filter: ${() =>
          useThemeVar(
            'invert(15%) sepia(34%) saturate(7060%) hue-rotate(268deg) brightness(78%) contrast(104%)',
            'invert(89%) sepia(72%) saturate(366%) hue-rotate(315deg) brightness(100%) contrast(101%)'
          )};
      }
    }

    &:first-child,
    &:last-child {
      margin: 0;
    }
  }

  &:last-child {
    margin: 0;
    margin-right: 0.4rem;
  }
`;

export const Details = styled.div`
  h2,
  a,
  span {
    color: ${() => useThemeVar(config.theme.primary, config.theme.secondary)};
  }

  h2 {
    margin-bottom: 0.5rem;
  }

  h4 {
    color: ${() => useThemeVar(config.theme.gray, config.theme.lightGray)};
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0;
  }

  @media (max-width: 680px) {
    margin-bottom: 2rem;
  }
`;
