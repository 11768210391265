module.exports = {
  defaultTitle: 'Griffen Fargo',
  logo: 'https://griffen.codes/favicon/favicon-512.png',
  author: 'Griffen Fargo',
  url: 'https://griffen.codes',
  legalName: 'Griffen Fargo',
  github: 'gfargo',
  defaultDescription: 'I’m Griffen and I’m a Full-Stack engineer!',
  social: {
    email: 'mailto:ghfargo@gmail.com',
    facebook: '', // AppId
    twitter: 'http://www.twitter.com/ghfargo',
    twitterHandle: 'ghfargo',
    github: 'https://github.com/gfargo',
    githubHandle: 'gfargo',
    linkedin: 'https://www.linkedin.com/in/griffenfargo',
    instagram: 'https://instagram.com/gfargo',
    instgramHandle: 'gfargo',
    youtube: 'https://www.youtube.com/user/gfargo',
    google: '',
  },
  routes: {
    posts: 'posts',
    post: 'post',
    projects: 'projects',
    project: 'project',
    tags: 'tags',
    categories: 'topics',
  },
  googleAnalyticsID: 'UA-88875900-4',
  theme: {
    primary: '#511281',
    secondary: '#FFD371',
    tertiary: '#4CA1A3',
    black: '#1a1917',
    primaryBg: '#21094E',
    white: '#fff',
    lightBg: '#efeeff',
    darkBg: '#212121',
    gray: '#707070',
    headerDark: '#a3a3a3',
    lightGray: '#c7c7c7',
    darkGray: '#3d4852',
    hightlight: '#E9EDD3',
    error: '#FF4848',
    success: '#A5E1AD',
    info: '#ACDDDF',
  },
  address: {
    city: 'Winooski',
    region: 'VT',
    country: 'USA',
    zipCode: '05404',
  },
  contact: {
    email: 'GhFargo@gmail.com',
    phone: '(802) 922-1108',
  },
  foundingDate: '2006',
};
