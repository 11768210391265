import styled from 'styled-components';
import config from 'data/config';
import useThemeVar from 'hooks/useThemeVar';

export const Wrapper = styled.div`
  a {
    line-height: 1.5;
    color: ${() => useThemeVar(config.theme.black, config.theme.secondary)};
    text-decoration: none;
    transition: all 0.225s ease-in-out;

    &:hover {
      color: ${() => useThemeVar(config.theme.primary, config.theme.secondary)};
    }

    @media (max-width: 960px) {
    }
  }

  .nav-link {
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 1px solid ${config.theme.secondary};
    }
  }

  ${({ desktop }) =>
    desktop
      ? `
			align-items: center;
			display: flex;

			@media (max-width: 960px) {
					display: none;
			}

			a {
					margin-right: 1rem;

					&:last-child {
							margin-right: unset;
					}
			}
		`
      : `
			padding: 3rem;
			display: flex;
			flex-direction: column;

			a {
					margin-bottom: 1rem;

					&:last-child {
							margin-bottom: unset;
					}
			}
	`}
`;

export const IconWrapper = styled.div`
  display: flex;
  img {
    margin-bottom: 0;
  }
`;
