import styled from 'styled-components';
import config from 'data/config';
import useThemeVar from 'hooks/useThemeVar';

export const Statistic = styled.div`
  margin-bottom: 0.75rem;
  position: relative;
  z-index: 1;

  h4 {
    color: ${() => useThemeVar(config.theme.primary, config.theme.secondary)};
    margin-bottom: 0.45rem;
    font-size: 0.8rem;
  }

  p {
    text-align: right;
    font-size: ${({ fontSize }) => (fontSize === 'lg' ? '1.2rem' : '1rem')};
    margin-bottom: 0;
  }

  small {
    margin-left: 0.1rem;
    font-size: ${({ fontSize }) => (fontSize === 'lg' ? '0.85rem' : '1rem')};
  }
`;
