import React from 'react';
import { Footer } from 'components/theme';
import { Global } from './styles';
import './fonts.css';
import { useTheme } from 'hooks/useTheme';

export const Layout = ({ context, children }) => {
  const { theme } = useTheme();

  return (
    <>
      <Global theme={theme} />
      {children}
      <Footer context={context} />
    </>
  );
};
