import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  width: 90%;

  @media (min-width: 601px) {
    width: 90%;
  }

  @media (min-width: 993px) {
    width: 80%;
  }
`;

export const PostContainer = styled.div`
  max-width: 680px;

  @media (max-width: 767px) {
    width: 90%;
  }

  font-size: 1.15rem;
  line-height: 1.5rem;

  p {
    font-weight: 300;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.64rem;
  }

  h3 {
    font-size: 1.42rem;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'row')};
  flex-wrap: ${({ wrap }) => (wrap ? wrap : 'wrap')};
  gap: ${({ gap }) => (gap ? gap : '0.6rem')};
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
`;

export const FlexChild = styled.div`
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'flex-start'};
`;

export const TwoThirdsChild = styled.div`
  width: 66%;
  margin-left: auto;

  @media (max-width: 960px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const OneThirdsChild = styled.div`
  width: 33%;
  margin-left: auto;

  @media (max-width: 960px) {
    width: 100%;
    margin-left: 0;
  }
`;
