import { useTheme } from 'hooks/useTheme';

/**
 * Provides theme dependant A/B variable within Styled Components.
 *
 * @param {string} lightVariable String to use in CSS when using light theme.
 * @param {string} darkVariable String to use in CSS when using dark theme.
 * @returns
 */
const useThemeVar = (lightVariable, darkVariable) => {
  const { theme } = useTheme();

  if (!lightVariable || !darkVariable) {
    return 'inherit';
  }

  return theme === 'light' ? lightVariable : darkVariable;
};

export default useThemeVar;
