import { useTheme } from 'hooks/useTheme';
import React from 'react';
import { Bar, Wrapper } from './styles';

const Hamburger = ({ sidebar, toggle }) => {
  const { theme } = useTheme();

  return (
    <Wrapper sidebar={sidebar} onClick={() => toggle(!sidebar)}>
      <Bar top sidebar={sidebar} theme={theme} />
      <Bar mid sidebar={sidebar} theme={theme} />
      <Bar bottom sidebar={sidebar} theme={theme} />
    </Wrapper>
  );
};

export default Hamburger;
