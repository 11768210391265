import React from 'react';

const LINKS = [
  {
    id: 0,
    name: 'Telegram',
    link: 'https://t.me/gfargo',
    icon: '/icons/telegram.svg',
  },
  {
    id: 1,
    name: 'Github',
    link: 'https://github.com/gfargo',
    icon: '/icons/github.svg',
  },
  {
    id: 2,
    name: 'StackOverflow',
    link: 'https://stackoverflow.com/users/1731759/gfargo',
    icon: '/icons/stackoverflow.svg',
  },
  {
    id: 3,
    name: 'Twitter',
    link: 'https://twitter.com/ghfargo',
    icon: '/icons/twitter.svg',
  },
];

const Socials = () => {
  return LINKS.map(({ id, name, link, icon }) => (
    <a
      key={id}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={`follow me on ${name}`}
    >
      <img height="20" src={icon} alt={name} />
    </a>
  ));
};

export default Socials;
